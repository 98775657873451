<script>
import RsNonConformityMixin from "@/mixins/RsNonConformityMixin";

export default {
  name: "NonConformityPreventativeMeasure",
  mixins:[RsNonConformityMixin],
  data() {
    return {
      toEdit: false,
      toolbar: [
        ['bold', 'italic', 'strike', 'underline'],
        [{
          'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]
        }]
      ],
    }
  },
}
</script>

<template>
  <form @submit.prevent="confirmUpdateRsNonConformity">
    <div class="row">
      <div class="col-12 text-right">
        <template v-if="editNonConformity">
          <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="setNonConformityEdit" v-if="editNonConformity">Cancel</button>
          <button class="e-btn e-btn-blue ml-2" type="submit">Save</button>
        </template>
        <template v-else>
          <button class="e-btn e-btn-green ml-2 px-3" type="button" @click="setNonConformityEdit(true)">Edit Preventative Measures</button>
        </template>
      </div>
    </div>
    <table class="w-100">
      <tr>
        <td style="width: 25%">Questioner Section</td>
        <td>
          {{nonConformity.rsn_question.parent.main_question}}
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Question No.</td>
        <td>
          {{nonConformity.rsn_question.code}}
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Question</td>
        <td>
          {{nonConformity.rsn_question.main_question}}
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <hr />
        </td>
      </tr>
      <tr>
        <td>Description</td>
        <td>
          <vue-editor  class="my-2" v-if="editNonConformity" :editor-toolbar="toolbar" v-model="nonConformityField.preventative_measure.description"></vue-editor>
          <div v-else class="pre-wrap-txt textarea-display-card my-2" v-html="nonConformity.preventative_measure.description"/>
        </td>
      </tr>
      <tr>
        <td>Detail</td>
        <td>
          <vue-editor  class="my-2" v-if="editNonConformity" v-model="nonConformityField.preventative_measure.detail" :editor-toolbar="toolbar"></vue-editor>
          <div v-else class="pre-wrap-txt textarea-display-card my-2" v-html="nonConformity.preventative_measure.detail"/>
        </td>
      </tr>
    </table>
  </form>
</template>

<style scoped>
table td {
  padding: 2px !important;
}
</style>
